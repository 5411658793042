import React from "react"
import Layout from "../../components/layout"
import background from "../../Figures/Site_Header_Background.png"
import dial_front from "../../Figures/Dial_front.jpg"
import dial_rear from "../../Figures/Dial_rear.jpg"
import { Link } from "gatsby"
import BellRinging from "./BellRinging"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO001Overview.js"


export default function RotaryDial() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO001 - Rotary cellphone</h1>
      <br />
      <h2 style={{color: "white"}}>Reading the Rotary dial</h2>
        </div>
      </div>
      </div>
      <div style={{}}>
      <div className={pageLayout.text}>
        <h2> The phone</h2>
        <h3>Intro</h3> 
        <p>
        I recently bought an old RTT 72 phone and I wanted to give it a second life. <br />
        The first idea was to only reuse the horn of the phone such that I would be able to connect my cellphone through the jack connection. This soon became too little of a challange so I wanted to make the phone a full stand alone unit that can be used to make and receive calls.   
        <br />
        For this I shall request the help on an Arduino.</p>
        <figure>
        <img width="30%"  src={dial_front} style={{borderRadius: 20, marginLeft: "35%" , marginRight: "35%"}} alt="Rotary dial front" />
        <figcaption style={{textAlign: "center"}}>Fig.1 - Front of the rotary dial mechanism.</figcaption>
        </figure>
        <h3>The rotary dial</h3>
        <p>No rotary encoder was needed to read in the signal, as the original mechanism could be used. Two digital inputs are needed to cature the numbers. <br />
        One changes state the entire time the dial is turning and the other one gives a certain amount of pulses, equal to the number dialed. <br />
        So, two digital inputs are reserved on the Arduino. The figure below shows the two parts of the mechanism registering the dialed numbers.</p>
        <figure>
        <img width="30%"  src={dial_rear} style={{borderRadius: 20, marginLeft: "35%" , marginRight: "35%"}} alt="Rotary dial rear" />
        <figcaption style={{textAlign: "center"}}>Fig.2 - Rear of the rotary dial mechanism showing the two components.</figcaption>
        </figure>
        <p>Two resistors and a connection to the ground are needed to correctly measure the signals. At first I tried hardware interrupts to register the pulses, but the result whas not as expected. More detailed investigation with the 'Serial Plotter' of the Arduino IDE at a high CAN rate showed that the signals sometimes switched states when not expected, So I moved to using normal digital inpus and compared two values measured consecutively with a 10 ms delay.</p>
        <p>Below an extract of the Arduino code I used for this part:</p>
        <pre style={{maxHeight:"500px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
         <code>{` 
int digitalPin_cnt = 6;          // Pin 6 is input connected to the number pulses of rotary dial
int digitalPin_reset = 7;        // Pin 7 is input connected to the single pulse showing rotary dial usage

float pulscount = 0;             // measured state of the 'digitalPin_cnt' signal
float pulscount_prev = 0;        // previous state of the 'digitalPin_cnt' signal
float reset = 0;                 // measured state of the 'digitalPin_reset' signal
float reset_prev = 0;            // previous state of the 'digitalPin_reset' signal
int count = 0;                   // variable counting the amount of pulses received when dialing a number
String PHnumber = "";            // string capturing the full phone number dialed
unsigned long timer;

void setup() {
  // start serial communication
  Serial.begin(9600);
  // set pin modes
  pinMode(digitalPin_cnt, INPUT);
  pinMode(digitalPin_reset, INPUT);
  // initiate the timer
  timer = micros();
}
void loop() {
  delay(10); // to filter out erractic signals
  pulscount = digitalRead(digitalPin_cnt);
  reset = digitalRead(digitalPin_reset);
// detect the pulses of the number dialed if the rotary dial is in motion
  if (pulscount == 0 && pulscount_prev == 1 && reset == 1) {
    count = count + 1;
    timer = micros();
  }
// If the rotary dial is no longer in motion the number dialed is captured
  if ( reset == 0 && reset_prev == 1) {
    PHnumber.concat(String(count % 10)); // a modulo operator because the '0' is 11 pulses
    count = 0;
    Serial.println(PHnumber);
    timer = micros();
  }
//If 'PHnumber' is not empty and nothing happens within 3s you capture the fully dialed number
  if (micros() - timer >= 3000000 && PHnumber != ""){
    Serial.println(PHnumber);
    PHnumber = "";
    timer = micros();
  }
  pulscount_prev = pulscount;
  reset_prev = reset;
}
         `}</code>
        </pre>
      </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}